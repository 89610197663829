import {useFormikContext} from 'formik';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useEditExtensionCallForwardingFormData} from '../../../../hooks/useEditExtensionCallForwardingForm';
import {actions} from '../../../../store';
import {ReduxState} from '../../../../store/types';
import {ChangeForwardingModeStateItem} from '../../../../store/types/CallScreening';
import {ForwardModeFlag} from '../../../../store/types/ServiceFeature';
import {Service, ServiceIcon} from '../../../Extensions/ServiceIcon';
import CustomizedRadio from '../../../Radio/Radio';
import SwitchWithLabel from '../../../SwitchWithLabel/SwitchWithLabel';
import CustomizedTooltip from '../../../Tooltip/Tooltip';
import {
    callForwardingRuleEmptyRule,
    ExtensionCallForwardingFormProps,
    ExtensionCallForwardingFormType,
    useStyles,
} from './CallForwardingForm.utils';
import FollowMeRulesList from './FollowMeRulesList';
import FollowRuleForm from './FollowRuleForm';
import {FollowMeSequence} from "../../../../store/types/AccountFollowMeSettings";

const CallForwardingForm: React.VFC<ExtensionCallForwardingFormProps> = (
    {
        permittedSipProxies,
        handleDirtyChange,
        handleSetSubmitFunc,
        handleSetIsValidFunc,
    }) => {

    const classes = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [temp, setTemp] = useState<ExtensionCallForwardingFormType | undefined>(undefined);

    const {
        values,
        handleSubmit,
        setFieldValue,
        setFieldError,
        setValues,
        setErrors,
        errors,
        dirty,
        isValid
    } = useFormikContext<ExtensionCallForwardingFormType>();

    const {initFormData} = useEditExtensionCallForwardingFormData();

    const {changeForwardingModeState} = useSelector(
        (state: ReduxState) => state.extensions,
    );

    useEffect(() => {
        handleDirtyChange?.('callForwarding', dirty);
    }, [dirty]);

    useEffect(() => {
        handleSetSubmitFunc?.('callForwarding', handleSubmit);
    }, [handleSubmit]);

    useEffect(() => {
        handleSetIsValidFunc?.('callForwarding', () => isValid);
    }, [isValid]);

    useEffect(() => {
        setTemp({
            ...initFormData
        })
    }, [initFormData]);


    const forwardModesList = useMemo(
        () =>
            Object.values(ForwardModeFlag)
                .slice(2)
                .map((v) => ({
                    name: t(`enums:forwardModeEnum.${v}`),
                    value: v,
                })),
        [],
    );

    const onChangeForwardingMode = useCallback(
        (v: { name: string; value: ForwardModeFlag }) => {

            const previousValue = values.callForwardingMethod;
            setFieldValue('callForwardingMethod', v.value);

            const updValue: ChangeForwardingModeStateItem = {
                flag: previousValue,
                callForwardingRules: values.callForwardingRules
            };

            const foundInState = changeForwardingModeState.stateValue.find(e => e.flag === v.value);

            if (foundInState?.callForwardingRules?.length) {
                setFieldValue('callForwardingRules', foundInState.callForwardingRules);
            } else {
                if (
                    v.value === ForwardModeFlag.SimpleForwarding ||
                    v.value === ForwardModeFlag.ForwardToSIPUri
                ) {
                    setFieldValue('callForwardingRules', [
                        {...callForwardingRuleEmptyRule},
                    ]);
                } else {
                    setFieldValue('callForwardingRules', []);
                }

                if(v.value === ForwardModeFlag.SimpleForwarding)
                {
                    setFieldValue('callForwardingRuleOrder', FollowMeSequence.Order);
                }
            }

            dispatch(actions.updateChangeForwardingModeState.request(updValue));
        },
        [setFieldValue, values, initFormData, changeForwardingModeState],
    );

    const setCallForwardingStatus = (field: string, value: boolean) => {

        if (field === 'callForwardingStatus') {

            if (!value) {
                setTemp(values);
                setValues({...initFormData, callForwardingStatus: value});
            } else {
                temp && setValues({...temp, callForwardingStatus: value});
            }
        }
    };

    useEffect(() => {

        if (values.callForwardingStatus && values.callForwardingMethod == ForwardModeFlag.NoForwarding) {
            setFieldValue('callForwardingMethod', ForwardModeFlag.FollowMe);
        }

    }, [values.callForwardingStatus]);

    useEffect(() => {
        setErrors({});
    }, [values.callForwardingMethod]);

    return (
        <div className={classes.mainContainer}>
            <SwitchWithLabel
                id="callForwardingStatus"
                field="callForwardingStatus"
                label={t('screens:extensions.callForwarding')}
                value={values.callForwardingStatus}
                disabled={values.callForwardingLocked}
                setValue={setCallForwardingStatus}
                icon={
                    <ServiceIcon
                        type={Service.CallForwarding}
                        dataQa="call-forwarding-service"
                        dataTestId="call-forwarding-service"
                    />
                }
            />

            {values.callForwardingStatus && (
                <div className={classes.contentContainer}>
                    <CustomizedTooltip
                        title={t('tooltips:incomings.locked')}
                        copy={false}
                        disableHoverListener={!values.callForwardingLocked}
                    >
                        <div className={classes.row}>
                            {forwardModesList.map((v) => (
                                <CustomizedRadio
                                    key={v.value}
                                    value={v.value}
                                    className={classes.radioContainer}
                                    checked={
                                        values.callForwardingMethod === v.value
                                    }
                                    label={v.name}
                                    dataQa={`call-forwarding-${v.value}`}
                                    onChange={() => onChangeForwardingMode(v)}
                                    disabled={values.callForwardingLocked}
                                />
                            ))}
                        </div>
                    </CustomizedTooltip>

                    {(values.callForwardingMethod ===
                        ForwardModeFlag.ForwardToSIPUri ||
                        values.callForwardingMethod ===
                        ForwardModeFlag.SimpleForwarding) && (
                        <FollowRuleForm
                            setFieldError={(field, value) => {
                                setFieldError(
                                    `callForwardingRules.[0].${field}`,
                                    value,
                                );
                            }}
                            setFieldValue={(field, value) => {
                                setFieldValue(
                                    `callForwardingRules.[0].${field}`,
                                    value,
                                );
                            }}
                            rule={values.callForwardingRules[0]}
                            permittedSipProxies={permittedSipProxies || ['']}
                            errors={errors?.callForwardingRules?.[0]}
                            mode={values.callForwardingMethod}
                        />
                    )}

                    {(values.callForwardingMethod ===
                        ForwardModeFlag.FollowMe ||
                        values.callForwardingMethod ===
                        ForwardModeFlag.AdvancedForwarding) && (
                        <FollowMeRulesList/>
                    )}
                </div>
            )}
        </div>
    );
};

export default CallForwardingForm;
