import React from 'react';
import {PermissionContext, usePermissions} from '../../hooks/usePermissions';
import {PermissionType} from '../../store/types/Permission';

type PermissionProviderProps = {
    permission: string[];
    skipReadOnly?: boolean;
    customizePermission?: (permissionValue: PermissionType) => PermissionType;
    debugName?:string,
    segmentedUserAccountRestricted?:boolean
};

const PermissionProvider: React.FC<PermissionProviderProps> = ({
    permission,
    children,
    skipReadOnly,
    customizePermission,
    debugName,
    segmentedUserAccountRestricted
}) => {
    let permissionValue = usePermissions(...permission);
    if(customizePermission) {
        permissionValue = customizePermission(permissionValue);
    }

    if(debugName)
    {
        console.log('debug:', 'value:',permissionValue, "name:", debugName)
    }

    return (
        <PermissionContext.Provider
            value={
                segmentedUserAccountRestricted ? PermissionType.Hidden :
                skipReadOnly
                    ? permissionValue === PermissionType.ReadOnly
                        ? PermissionType.Visible
                        : permissionValue
                    : permissionValue
            }
        >
            {children}
        </PermissionContext.Provider>
    );
};

export default PermissionProvider;
