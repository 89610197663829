import {useSelector} from "react-redux";
import {ReduxState} from "../../store/types";
import {useMemo} from "react";
import {v4 as uuidv4} from "uuid";
import {getRoute, Routes} from "../../routes/routes";
import {getDispatchType} from "../../utils/extensions/AddExtensionToRingGroup";
import {ExtensionType} from "../../store/types/Extension";
import {DispatchType} from "../../components/Extensions/RingGroupsInExtension/AddToRingGroupDialog.utils";
import {Colors} from "../../styles/Colors";
import {SipCall, SipCallState, SipCallType, UpdatedRingGroupMember} from "../../store/actions/ringgroups/payloads";
import i18n from "../../services/i18n";

const isOnCallState = (state: SipCallState, type: SipCallType) => {
    return (state === SipCallState.Connected)
        || (state === SipCallState.Trying && type === SipCallType.Outgoing)
        || (state === SipCallState.Ringing && type === SipCallType.Outgoing)
        || (state === SipCallState.Queued && type === SipCallType.Outgoing)
        || (state === SipCallState.Dequeued)
}

const isOnHoldState = (state: SipCallState) => {
    return (state === SipCallState.Holding || state === SipCallState.Held)

}

const isOnRingingState = (state: SipCallState, type: SipCallType) => {
    return (state === SipCallState.Ringing && type === SipCallType.Incoming)
        || (state === SipCallState.Trying && type === SipCallType.Incoming)
}

const getStatus = (extension: ExtensionType, calls: SipCall[]) => {

    let statusText = 'unknown'
    let statusColor = undefined
    let callType = undefined
    let duration = undefined
    let isOnHold = false

    const onCallIncome = calls.find(e => (
            e.callee?.extension_id === extension.extension_id) &&
        isOnCallState(e.state, e.type)
    )

    const onCallOutgoing = calls.find(e => (
            e.caller?.extension_id == extension.extension_id) &&
        isOnCallState(e.state, e.type)
    )

    const onHoldIn = calls.find(e => (e.callee?.extension_id === extension.extension_id) &&
        isOnHoldState(e.state))

    const onHoldOut = calls.find(e => (e.caller?.extension_id == extension.extension_id) &&
        isOnHoldState(e.state))

    const onRinging = calls.find(e => (
            e.callee?.extension_id === extension.extension_id) &&
        isOnRingingState(e.state, e.type)
    )

    if (onCallIncome || onCallOutgoing) {
        statusText = i18n.t<string>('screens:wallboard.onCallCount')
        statusColor = Colors.Error
        callType = onCallIncome?.type ? SipCallType.Incoming : SipCallType.Outgoing
        duration = onCallIncome?.duration || onCallOutgoing?.duration
    } else if (onHoldIn || onHoldOut) {
        statusText = i18n.t<string>('screens:wallboard.onHoldCount')
        statusColor = Colors.Primary2
        callType = onHoldIn?.type ? SipCallType.Incoming : SipCallType.Outgoing
        duration = onHoldIn?.duration || onHoldOut?.duration
        isOnHold = true
    } else if (onRinging) {
        statusText = i18n.t<string>('screens:wallboard.ringingCount')
        statusColor = Colors.Support
        callType = onRinging.type
        duration = onRinging.duration
        isOnHold = false
    } else {
        switch (extension?.sip_status) {
            case 1:
                // statusText = 'Idle'
                statusText = i18n.t<string>('screens:wallboard.idleCounts')
                statusColor = Colors.Support
                break
            case 0:
                // statusText = 'Unregistered'
                statusText = i18n.t<string>('screens:wallboard.unregisteredCount')
                statusColor = Colors.Text3
                break
        }
    }

    return {
        text: statusText,
        color: statusColor,
        callType,
        duration,
        isOnHold
    }

}

export interface AgentsTypeData {
    id: string,
    groupName?: string,
    show?: boolean,
    isGroup: boolean,
    i_c_group?: number,
    link?: string,
    extension?: string,
    name?: string,
    custom_id?: string,
    status?: {
        text: string,
        color?: string,
        callType?: SipCallType,
        duration?: string
        isOnHold: boolean
    },
    duration?: string,
    isLogged?: boolean,
    updatedRingGroupMember?: UpdatedRingGroupMember,
    showIcon: boolean
}

export const useAgentsHook = () => {

    const {
        accounts,
        huntGroupList,
        sipCalls,
        isLoading,
        agents,
        agentsCache
    } = useSelector((state: ReduxState) => state.agents);

    let data = useMemo(() => {
        const output: AgentsTypeData[] = [];

        huntGroupList?.huntgroup_list.forEach(group => {
            output.push({
                id: group.name,
                custom_id: group.name,
                groupName: group.name,
                isGroup: true,
                link: getRoute(Routes.RingGroupsDetails, {id: group.i_c_group}),
                i_c_group: group.i_c_group,
                showIcon: false
            })

            if (group.assigned_extensions) {
                const temp = [...group.assigned_extensions]
                    .filter(ext => ext.type == 'Account');

                temp.forEach(extensionItem => {
                    const dispatchType = extensionItem.hunt_active === 'Y'
                        ? getDispatchType(extensionItem.hunt_delay, extensionItem.hunt_expire)
                        : DispatchType.Disabled

                    const account = accounts?.account_list.find(
                        acc => acc.extension_id == extensionItem.id
                    );

                    // @ts-ignore
                    const status = getStatus(account, sipCalls)

                    output.push({
                        groupName: group.name,
                        isGroup: false,
                        id: uuidv4(),
                        custom_id: uuidv4(),
                        extension: extensionItem.id || '',
                        name: extensionItem.name || '',
                        duration: 'some duration',
                        isLogged: dispatchType !== DispatchType.Disabled,
                        // @ts-ignore
                        status: status,
                        updatedRingGroupMember: {
                            i_c_group: group.i_c_group,
                            i_ringback_tone: group.i_ringback_tone,
                            extension: extensionItem
                        },
                        showIcon: status.callType ? [SipCallType.Incoming, SipCallType.Outgoing].includes(status.callType) : false
                    })
                })
            }

        })

        return output

    }, [huntGroupList, accounts, sipCalls, agents]);


    const filterTemp = [...data];
    data = data.map(item => {


        if (item.isGroup) {

            item.show = !!filterTemp.find(member => !member.isGroup && member.groupName == item.groupName)

        } else {
            item.show = true
        }


        return item;
    });

    data = data.filter(item => item.show)

    return {
        data: data,
        agentsItemsForFilter: agents,
        agentsCache,
        isLoading
    }
}