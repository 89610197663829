import React, {useEffect, useMemo} from "react";
import SwitchMode from "../SwitchMode/SwitchMode";
import Header from "../ListViewHeader/Header";
import HeaderStat from "../Calls/CallStatistics";
import PermissionProvider from "../PermissionProvider/PermissionProvider";
import {Permission} from "../../store/types/Permission";
import {makeStyles} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {ReduxState} from "../../store/types";
import {ActiveCall} from "../../store/types/CallHistory";
import {useTranslation} from "react-i18next";
import {actions} from "../../store";


export const useStyles = makeStyles(() => ({
    headerText:{
        '& h2':{
            marginBlockEnd: 0,
            marginBlockStart: 0,
        }
    },
    loader: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    rightSection: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    switchMode: {
        display: 'flex',
    },
    statsContainer: {
        display: "flex",
        alignItems: 'center'
    }
}));

export const supervisorHeaderDataTestId = 'supervisor-header-component'

const SupervisorHeader = () => {

    const classes = useStyles()
    const {t} = useTranslation()
    const dispatch = useDispatch()

    const {sipCallsList} = useSelector(
        (state: ReduxState) => state.ringgroups,
    );

    useEffect(() => {
        dispatch(actions.getSupervisorHeaderData.request())
    }, []);


    const isCallsDoughnutDataLoading: boolean = useSelector((state: ReduxState) =>
        (state.calls?.isCallsDoughnutExtensionsLoading ?? false)
        || (state.calls?.isCallsDoughnutSipCallsLoading ?? false));


    const switchMode = useMemo(
        () => <SwitchMode/>,
        [],
    );

    return (
        <div className={classes.header} data-testid={supervisorHeaderDataTestId}>
            <Header title={t('screens:supervisorPanel.headerTitle')} hideTotal customClass={classes.headerText}/>
            <div className={classes.statsContainer}>
                <div className={classes.rightSection}>
                    <HeaderStat calls={sipCallsList || []} isLoading={isCallsDoughnutDataLoading}/>
                </div>

                <PermissionProvider
                    permission={
                        Permission.CloudPBX.Extensions.SwitchNode.value
                    }
                >
                    <div className={classes.switchMode}>
                        {switchMode}
                    </div>
                </PermissionProvider>
            </div>
        </div>
    )

}

export default SupervisorHeader