import { createAction, createAsyncAction } from 'typesafe-actions';
import * as payloads from './payloads';
import { RecordingsPlayingState } from '../../reducers/calls/reducer';
import { APIErrorInterface } from '../../types';
import { ExtensionType } from '../../types/Extension';
import { SipCall } from '../ringgroups/payloads';

export const getExtensionCallHistory = createAsyncAction(
    'GET_EXTENSION_CALL_HISTORY',
    'GET_EXTENSION_CALL_HISTORY_SUCCESS',
    'GET_EXTENSION_CALL_HISTORY_FAILED',
)<
    payloads.GetExtensionCallHistoryRequestPayload,
    payloads.GetCallHistoryResponsePayload,
    undefined
>();

export const getExtensionCallHistoryFile = createAsyncAction(
    'GET_EXTENSION_CALL_HISTORY_FILE',
    'GET_EXTENSION_CALL_HISTORY_FILE_SUCCESS',
    'GET_EXTENSION_CALL_HISTORY_FILE_FAILED',
)<payloads.GetExtensionCallHistoryFileRequestPayload, undefined, undefined>();

export const setCustomerCurrencyForCallHistory = createAction(
    'SET_CUSTOMER_CURRENCY_FOR_CALL_HISTORY',
)<payloads.SetCustomerCurrencyForCallHistoryPayload>();

export const getCallHistory = createAsyncAction(
    'GET_CALL_HISTORY',
    'GET_CALL_HISTORY_SUCCESS',
    'GET_CALL_HISTORY_FAILED',
)<
    payloads.GetCallHistoryRequestPayload,
    payloads.GetCallHistoryResponsePayload,
    undefined
>();

export const removeCallRecordings = createAsyncAction(
    'REMOVE_CALL_RECORDING',
    'REMOVE_CALL_RECORDING_SUCCESS',
    'REMOVE_CALL_RECORDING_FAILED',
)<payloads.RemoveCallRecoringsRequestPayload, undefined, undefined>();

export const getActiveCalls = createAsyncAction(
    'GET_ACTIVE_CALLS',
    'GET_ACTIVE_CALLS_SUCCESS',
    'GET_ACTIVE_CALLS_FAILED',
)<{serviceType?:number} | undefined, payloads.GetActiveCallsResponsePayload, undefined>();

export const getCallHistoryBasicData = createAsyncAction(
    'GET_CALL_HISTORY_BASIC_DATA',
    'GET_CALL_HISTORY_BASIC_DATA_SUCCESS',
    'GET_CALL_HISTORY_BASIC_DATA_FAILED',
)<undefined, payloads.GetCallHistoryBasicDataResponsePayload, undefined>();

export const getSupervisorHeaderData = createAsyncAction(
    'GET_SUPERVISOR_HEADER_DATA',
    'GET_SUPERVISOR_HEADER_DATA_SUCCESS',
    'GET_SUPERVISOR_HEADER_DATA_FAILED',
)<undefined, undefined, undefined>();


export const getInitialCallHistoryBasicData = createAsyncAction(
    'GET_INITIAL_CALL_HISTORY_BASIC_DATA',
    'GET_INITIAL_CALL_HISTORY_BASIC_DATA_SUCCESS',
    'GET_INITIAL_CALL_HISTORY_BASIC_DATA_FAILED',
)<undefined, undefined, undefined>();

export const getCallHistoryFileAsBlob = createAsyncAction(
    'GET_CALL_HISTORY_FILE_AS_BLOB',
    'GET_CALL_HISTORY_FILE_AS_BLOB_SUCCESS',
    'GET_CALL_HISTORY_FILE_AS_BLOB_FAILED',
)<payloads.GetCallHistoryFileAsBlobRequestPayload, undefined, undefined>();

export const updateRecordingsPlayingState = createAction(
    'UPDATE_CALL_HISTORY_RECORDING_STATE',
)<RecordingsPlayingState>();

export const getCallHistoryDataForStatistics = createAsyncAction(
    'GET_CALL_HISTORY_DATA_FOR_STATISTICS',
    'GET_CALL_HISTORY_DATA_FOR_STATISTICS_SUCCESS',
    'GET_CALL_HISTORY_DATA_FOR_STATISTICS_FAILED',
)<payloads.GetCallHistoryDataForStatisticsPayload, payloads.CallHistoryDataForStatisticsResponse, APIErrorInterface>();

export const getExtensionsListForCallsDoughnut = createAsyncAction(
    'GET_EXTENSIONS_FOR_CALLS_DOUGHNUT',
    'GET_EXTENSIONS_FOR_CALLS_DOUGHNUT_SUCCESS',
    'GET_EXTENSIONS_FOR_CALLS_DOUGHNUT_FAILED',
)<{skipService?:boolean, skipDid?:boolean, limit?:number}, ExtensionType[], APIErrorInterface>();

export const getCallHistoryDataForCallsDoughnut = createAsyncAction(
    'GET_CALLS_DOUGHNUT_CALL_HISTORY',
    'GET_CALLS_DOUGHNUT_CALL_HISTORY_SUCCESS',
    'GET_CALLS_DOUGHNUT_CALL_HISTORY_FAILED',
)<undefined, SipCall[], APIErrorInterface>();

export const checkIfTranscriptionExist = createAsyncAction(
    'CHECK_IF_TRANSCRIPTION_EXIST_REQUEST',
    'CHECK_IF_TRANSCRIPTION_EXIST_SUCCESS',
    'CHECK_IF_TRANSCRIPTION_EXIST_FAILED',
)<{callRecordingId:string}, {transcriptionExist:boolean}, undefined>()

export const downloadTranscriptionFile = createAsyncAction(
    'DOWNLOAD_TRANSCRIPTION_FILE',
    'DOWNLOAD_TRANSCRIPTION_FILE_SUCCESS',
    'DOWNLOAD_TRANSCRIPTION_FILE_FAILED',
)<{callRecordingId:string}, undefined, undefined>()