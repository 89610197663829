import {put, takeLatest} from 'redux-saga/effects';

//Actions
import * as actions from '../../actions';
import {AxiosResponse} from "axios";
import {AccountListResponse} from "../../types/Account";
import {fetchAccountList} from "../generic/saga";
import {
    ApiRingGroupListRequestPayload,

    SipCall
} from "../../actions/ringgroups/payloads";
import {callApiGetSipCallsList} from "../ringgroups/saga";
import {ActionType} from "typesafe-actions";
import {AssignedExtension, RingGroupType} from "../../types/RingGroup";
import {pagedDataRequest} from "../paged.data.saga";
import {Customer} from "../../../services/endpoints";

export function* getAgentsTabData(
    action: ActionType<typeof actions.getAgentsTabData.request>,
) {


    try {
        const accounts: AxiosResponse<AccountListResponse> = yield fetchAccountList(
            undefined,
            {
                "has_extension": 1,
                "get_only_real_accounts": 1,
                "get_not_closed_accounts": 1,
                "limit": 100
            },
            true,
            true,
            true
        );

        const nameFilters: string[] = [];
        action.payload?.ringGroupFilter && nameFilters.push(action.payload?.ringGroupFilter.name)

        let hgPayload: ApiRingGroupListRequestPayload
        let huntGroupList: RingGroupType[] = []


        if (!nameFilters.length) {
            hgPayload = {
                limit: action.payload?.pageSize || 50,
                offset: action.payload?.page || 0,
                name: undefined
            }

            huntGroupList =
                yield pagedDataRequest<RingGroupType>(Customer.GetHuntGroupList, hgPayload, (data) => data.huntgroup_list);


            huntGroupList = huntGroupList.map(item => ({...item, cache_assigned_extensions: item.assigned_extensions}))

            const filteredTemp: RingGroupType[] = []

            if (action.payload.agentsFilter?.length) {

                huntGroupList.forEach(tempGroup => {

                    const newExtensions: AssignedExtension[] = []

                    action.payload.agentsFilter?.forEach(agent => {
                        tempGroup.assigned_extensions?.forEach(ext => {
                            if (ext.id == agent.id) {
                                newExtensions.push(ext)
                            }
                        })
                    })

                    filteredTemp.push({...tempGroup, assigned_extensions: newExtensions})

                });

                huntGroupList = [...filteredTemp]

            }


        } else {

            for (let i = 0; i < nameFilters.length; i++) {

                hgPayload = {
                    limit: action.payload?.pageSize || 50,
                    offset: action.payload?.page || 0,
                    name: nameFilters[i]
                }

                let temp: RingGroupType[] =
                    yield pagedDataRequest<RingGroupType>(Customer.GetHuntGroupList, hgPayload, (data) => data.huntgroup_list);

                temp = temp.map(item => ({...item, cache_assigned_extensions: item.assigned_extensions}))

                const filteredTemp: RingGroupType[] = []

                if (action.payload.agentsFilter?.length) {

                    temp.forEach(tempGroup => {

                        const newExtensions: AssignedExtension[] = []

                        action.payload.agentsFilter?.forEach(agent => {
                            tempGroup.assigned_extensions?.forEach(ext => {
                                if (ext.id == agent.id) {
                                    newExtensions.push(ext)
                                }
                            })
                        })

                        filteredTemp.push({...tempGroup, assigned_extensions: newExtensions})

                    });

                    huntGroupList = [...huntGroupList, ...filteredTemp]

                } else {
                    huntGroupList = [...huntGroupList, ...temp]
                }

            }
        }

        const sipCallsData: SipCall[] = yield callApiGetSipCallsList(actions.getSipCallsList.request({}))


        const tempAgents: AssignedExtension[] = []

        huntGroupList.forEach(group => {

            if (group.assigned_extensions?.length) {
                group.assigned_extensions.forEach(item => !tempAgents.find(tempItem => tempItem.id == item.id && item.type == 'Account') && tempAgents.push(item))
            }
        })

        if (action.payload.isInit) {
            yield put(actions.cacheAgents({agents: tempAgents}))
        }


        yield put(actions.getAgentsTabData.success({
            accounts: {...accounts.data},
            huntGroupList: {huntgroup_list: [...huntGroupList], total: huntGroupList.length},
            sipCalls: sipCallsData,
            agents: tempAgents
        }));

    } catch (err) {
        yield put(actions.getAgentsTabData.failure());
    }
}


export const agentsSaga = [
    takeLatest(actions.getAgentsTabData.request, getAgentsTabData),
];