import React, {useEffect, useRef, useState} from 'react';
import {Colors} from '../../styles/Colors';
import HelperText from '../MultipleInputTextField/HelperText';
import classNames from 'classnames';
import {AsyncPaginate} from 'react-select-async-paginate';
import {ArrowDropDown, Close} from '@material-ui/icons';
import {IconButton} from '@material-ui/core';
import {usePermissionContext} from "../../hooks/usePermissions";
import {PermissionType} from "../../store/types/Permission";
import {AsyncSelectFieldProps, customStyle, useStyle} from "./AsyncSelectField.utils";

const AsyncSelectField: React.FC<AsyncSelectFieldProps> = (
    {
        id,
        className,
        title,
        onChange,
        dataQa,
        helperText,
        icon,
        loadOptions,
        onInputChange,
        value,
        cacheUniqs,
        menuIsOpen,
        onMenuClose,
        onMenuOpen,
        maxMenuHeight,
        disabled,
        defaultValue,
        required,
        setFieldError,
        disableClearable,
        defaultOptions,
        formatOptionLabel,
        filterOption,
        isOptionDisabled,
        isOptionSelected,
        forceEnabled,
        useAny = false,
        inDialog = false,
        isMulti = false,
        closeMenuOnSelect = true,
        hideSelectedOptions = true,
        placeholder
    }) => {
    const classes = useStyle();
    const permission = usePermissionContext();
    const isDisable = forceEnabled ? false : permission == PermissionType.ReadOnly || disabled;
    const isReadonly = forceEnabled ? false : permission == PermissionType.ReadOnly && !disabled;
    const ref = useRef<HTMLInputElement>(null);
    const ref2 = useRef<HTMLInputElement>(null);

    const [focus, setFocus] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [useTopStyle, setUseTopStyle] = useState(false);
    const [hideCursor, setHideCursor] = useState(true)


    useEffect(() => {
        setUseTopStyle(!!(value || inputValue.length || useAny && defaultValue?.label && !defaultValue?.value))
    }, [value, inputValue, useAny, defaultValue]);

    const components = {
        ClearIndicator: function ClearIndicator(props: any) {
            return (
                <IconButton
                    size="small"
                    className={classNames(classes.closeButton, 'close-button')}
                    onClick={props.clearValue}
                >
                    <Close style={{height: 20, width: 20}}/>
                </IconButton>
            );
        },
        DropdownIndicator: function Chevron(props: any) {
            return (
                <IconButton
                    size="small"
                    className={classNames(
                        classes.chevron,
                        props.isFocused && classes.rotated,
                    )}
                >
                    <ArrowDropDown htmlColor={Colors.LightGraySecondary2}/>
                </IconButton>
            );
        },
    };

    return (
        <div
            data-qa={dataQa}
            className={classNames(
                classes.root,
                focus && classes.focus,
                helperText && classes.errors,
                disabled && classes.disabledRoot,
                isReadonly && classes.readOnlyRoot,
                hideCursor && classes.hideCursor,
                className,
            )}
            onClick={() => !focus && setFocus(true)}
            onBlur={() => setFocus(false)}
        >
            <label
                htmlFor="multiple-input-field"
                className={classNames(
                    classes.label,
                    disabled && classes.disabledLabel,
                    useTopStyle && classes.top,
                )}
            >
                {title}
                {required ? <span className={classes.asterisk}> *</span> : ''}
            </label>
            <div className={classes.paginateContainer}>
                <AsyncPaginate
                    hideSelectedOptions={hideSelectedOptions}
                    closeMenuOnSelect={closeMenuOnSelect}
                    isMulti={isMulti}
                    inputId="multiple-input-field"
                    labelId={'demo-simple-select-label'}
                    menuPlacement={'auto'}
                    loadOptions={loadOptions}
                    getOptionLabel={(option) => {
                        return option.label;
                    }}
                    menuShouldBlockScroll={true}
                    isOptionSelected={isOptionSelected}
                    formatOptionLabel={formatOptionLabel}
                    selectRef={ref}
                    placeholder={placeholder ?? ''}
                    classNamePrefix={'multiple-input-select'}
                    onInputChange={(v, actionMeta) => {
                        v ? setHideCursor(false) : setHideCursor(true);
                        setInputValue(v);
                        onInputChange?.(v, actionMeta);
                    }}
                    value={value ?? defaultValue ?? null}
                    onChange={(value) => {
                        !!id && setFieldError?.(id, undefined);
                        onChange?.(value);
                    }}
                    isClearable={!inDialog && !disableClearable}
                    styles={customStyle}
                    components={components}
                    arrow
                    cacheUniqs={cacheUniqs}
                    debounceTimeout={800}
                    inputValue={inputValue}
                    menuIsOpen={menuIsOpen}
                    onMenuOpen={onMenuOpen}
                    onMenuClose={onMenuClose}
                    menuPortalTarget={document.body}
                    maxMenuHeight={maxMenuHeight}
                    isDisabled={isDisable}
                    blurInputOnSelect
                    defaultOptions={defaultOptions}
                    filterOption={filterOption}
                    isOptionDisabled={isOptionDisabled}
                    defaultValue={defaultValue}
                    autoFocus={true}

                />

                {inDialog && !disableClearable && value && (
                    <div className={classes.dialogClearContainer}>
                        <IconButton
                            size="small"
                            className={classNames(classes.closeButton, 'close-button')}
                            onClick={() => {
                                onChange?.(undefined);
                                ref2?.current?.focus()
                            }}
                        >
                            <Close style={{height: 20, width: 20}}/>
                        </IconButton>
                    </div>
                )}

                <HelperText
                    text={helperText}
                    dataQa="filter-number"
                    className={classes.helperText}
                />
                {icon}
            </div>
            <input ref={ref2} style={{opacity: 0, position: "fixed", top: 0, left: 0}}/>
        </div>
    );
};

export default AsyncSelectField;
