import React, {useEffect, useState} from 'react';
import {Grid, makeStyles} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import DialogContainer, {DialogButton} from '../AlertDialog/DialogContainer';
import {Colors} from '../../styles/Colors';
import CallRecordDots from './../Calls/CallRecordDots';
import {Call, SipCallType} from "../../store/actions/ringgroups/payloads";
import {useFormik} from "formik";
import {actions} from "../../store";
import {useDispatch} from "react-redux";
import ExtensionAsyncSelectFiled from "./ExtensionAsyncSelectFiled";
import {YesNo} from "../../store/types/CallScreening";
import {ExtensionsListItem} from "../../store/reducers/extensions/extensions/reducer";
import IconWithTooltip from "../Tooltip/IconWithTooltip";
import {ExtensionType} from "../../store/types/Extension";
import {displayCallerText} from "../../views/CallHistory/ActiveCalls/ActiveCalls.utils";
import {TransferCallFormType} from "./TransferCallDialog";

interface TransferCallDialogProps {
    isOpen: boolean;
    toggleVisibility?: () => void;
    onSaveClick?: () => void;
    caller: any;
    callee: any;
    call: Call;
    type: SipCallType;
    i_customer: number | string;
    accounts: ExtensionType[],


}

const useStyles = makeStyles(() => ({
    headerSectionContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    headerSection: {
        fontWeight: 700,
        fontSize: 18,
        lineHeight: '20px',
        marginRight: 14
    },

    option: {
        display: 'flex',
        flexDirection: 'column',
        '& .label': {
            lineHeight: '48px',
            color: Colors.Black,
            fontSize: 16,
            fontWeight: 400
        },

        '& .register, & .unregister': {
            fontSize: 14,
            fontWeight: 400,
            lineHeight: '16px',
        },

        '& .register': {
            color: Colors.Support
        },
        '& .unregister': {
            color: Colors.Gray
        }
    },
    primaryActionButton: {
        '& .MuiButton-label': {
            color: Colors.Primary,
        },
    },
    header: {
        '& .MuiTypography-h6': {
            fontWeight: 'bold',
            fontSize: 18,
        },
    },
    modalContainer: {
        '& .MuiDialogContent-root': {
            width: 604,
            backgroundColor: Colors.SmokeBackground,
        },
    },
    inputsContainer: {
        padding: '0 12px 0 12px',
        display: 'flex',
        flexDirection: 'column',
        '& .MuiInputBase-root': {
            background: Colors.SmokeBackground,
        },
    },
    inputRow: {
        display: 'flex',
        marginTop: 22,

        '& .MuiFormControl-root': {
            flex: 1,
            margin: '0 6px',
        },
    },
    iconMargin: {
        marginLeft: 10,
    },
    buttonsContainer: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
    },


    container: {
        '& .MuiPaper-root': {
            width: 336,
        },

        '& .MuiDialogContent-root': {
            minWidth: 'unset !important',
            padding: '24px 24px 26px 24px !important',
            background: `${Colors.White} !important`,
        },
        '& .MuiDialogActions-root': {
            '& > :first-child': {
                marginRight: 20,
            },
        },
    },

    radioContainer: {
        marginRight: 32,
    },
}));

export interface JoinCallFormType {
    call_info: {
        id: string,
        tag: string
    } | undefined,
    extension?: ExtensionsListItem,
    participant_info: {
        mute: YesNo,
    }
}


export interface AgentJoinCallFormType {
    call_info:Call,
    participant_info: {
        mute: YesNo,
        participant_id:string,
        coach?:YesNo
    },
    conference_info:{
        name:string,
        owner_id:string
    }
}

const AddParticipantCallDialog: React.VFC<TransferCallDialogProps> = (
    {
        isOpen,
        toggleVisibility,
        caller,
        callee,
        type,
        i_customer,
        call,
        onSaveClick,
        accounts
    }) => {

    const classes = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const initState = {
        call_info: {id: call.id, tag: call.tag},
        extension: undefined,
        participant_info: {mute: YesNo.No}
    }

    const [init, setInit] = useState<JoinCallFormType>(initState)

    const {setFieldValue, submitForm, dirty, values, setValues} = useFormik<JoinCallFormType>({
        // @ts-ignore
        initialValues: {
            ...init
        },
        onSubmit: (form) => {
            dispatch(actions.joinToActiveCall.request({
                ...form, callback: () => {
                    toggleVisibility?.();
                    onSaveClick?.();
                }
            }))
        }
    });

    useEffect(() => {
        if (isOpen) {
            setValues({
                ...initState, call_info: {id: call.id, tag: call.tag}
            })
        }

    }, [isOpen])

    const callerText = displayCallerText(accounts, caller);
    const calleeText = displayCallerText(accounts, callee);

    const leftText = callerText == 'call_supervision' || caller.id  == 'call_supervision' ? t('screens:calls.conference') : callerText;
    const rightText = calleeText == 'call_supervision' || callee.id  == 'call_supervision' ? t('screens:calls.conference') : calleeText;

    return (
        <DialogContainer
            isOpen={isOpen}
            dataQa="call-record-dialog"
            header={'Add participant to conference call'}
            headerClass={classes.header}
            className={classes.modalContainer}
            dialogActionsButtons={[
                <DialogButton
                    className={classes.primaryActionButton}
                    key="close"
                    label={t('common:cancel')}
                    onClick={toggleVisibility}
                />,
                <DialogButton
                    className={classes.primaryActionButton}
                    key="save"
                    label={t('common:Add')}
                    onClick={submitForm}
                    primary
                    disabled={!dirty}
                />,
            ]}
        >
            <CallRecordDots
                leftText={leftText.length ? leftText : caller.id}
                rightText={rightText.length ? rightText : callee.id}
                type={type == SipCallType.Incoming ? 8 : 4}
                forwardLine={true}
            />

            <Grid className={classes.inputsContainer}>
                <Grid>
                    <div className={classes.headerSectionContainer}>
                        <span className={classes.headerSection}>Select extension</span>
                        <IconWithTooltip
                            dataQa="add-participant-call-tooltip"
                            tooltipText={t(
                                'tooltips:calls.addParticipationTooltip',
                            )}
                        />
                    </div>
                </Grid>
                <Grid className={classes.inputRow}>
                    <ExtensionAsyncSelectFiled
                        disableUnRegistered={true}
                        value={values.extension}
                        i_customer={i_customer}
                        setValue={(value) => setFieldValue('extension', value)}
                        ignoreIds={[caller.id,callee.id]}
                        useExtensionFilter={true}
                    />
                </Grid>
            </Grid>

        </DialogContainer>
    );
};

export default AddParticipantCallDialog;
