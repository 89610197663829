import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../../styles/Colors";
import React from "react";
import {InputActionMeta} from "react-select";
import {FormatOptionLabelMeta} from "react-select/src/Select";
import {FilterOption} from "react-select-async-paginate/ts/types";

export const useStyle = makeStyles(() => ({
    dialogClearContainer: {
        display: 'flex',
        textAlign: 'right',
        marginRight: 40,
        top: -40,
        justifyContent: 'end',
        position: 'relative'
    },

    hideCursor: {
        '& .multiple-input-select__input > input': {
            color:'transparent !important'
        },
    },

    root: {
        position: 'relative',
        border: 'none',
        flex: 1,

        '&:hover': {
            '& .close-button': {
                visibility: 'visible',
            },
        },

        '& .css-2b097c-container': {
            flex: 1,
        },

        '& .multiple-input-select__control': {
            border: 'none',
            flex: 1,
            fontSize: 14,
            fontWeight: 500,
            '&:active': {
                border: 'none',
            },
            '&:before': {
                borderBottom: `1px solid ${Colors.Border}`,
            },
            '&:hover': {
                '&:before': {
                    borderBottom: `2px solid ${Colors.Text}`,
                    transition: 'border 200ms ease-out',
                },
            },
        },

        '& .multiple-input-select__value-container': {
            paddingTop: 16,
            paddingLeft: 16,
            '& .css-b8ldur-Input': {
                top: 4,
            },
            '& .multiple-input-select__single-value': {
                marginLeft: 0,
                top: '48%',
            },
        },

        '& .multiple-input-select__indicators': {
            display: 'flex',
            justifyContent: 'flex-end',
            marginRight: 10,
        },

        '& .multiple-input-select__control:after': {
            position: 'absolute',
            content: 'open-quote',
            bottom: 0,
            left: 0,
            width: '100%',
            borderBottom: '1px solid #000',
            color: 'transparent',
            zIndex: 1,
        },

        '& .multiple-input-select__control:before': {
            position: 'absolute',
            content: 'open-quote',
            bottom: '0',
            left: 0,
            width: '100%',
            borderBottom: `1px solid ${Colors.LightGraySecondary}`,
            color: 'transparent',
            zIndex: 2,
        },

        '& .css-1rhbuit-multiValue': {
            borderRadius: 25,
            backgroundColor: Colors.LightGraySecondary4,
            alignItems: 'center',
            height: 22,
            paddingRight: 6,
        },

        '& .multiple-input-select__multi-value__remove': {
            backgroundColor: Colors.LightGraySecondary3,
            color: 'white',
            width: 14,
            height: 14,
            borderRadius: 25,
            '&:hover': {
                backgroundColor: Colors.Gray6,
                color: 'white',
            },
            '& svg': {
                transform: 'scale(2.0)',
            },
        },

        '& .multiple-input-select__indicator-separator': {
            visibility: 'hidden',
        },

        '& .MuiTableCell-body': {
            color: 'red',
        },

        '& .multiple-input-select__placeholder': {
            fontSize: 11,
            marginTop: 15,
            fontWeight: 400,
            position: 'unset !important',
            marginLeft: -5,
            color: Colors.LightGraySecondary,
        },

        '& .multiple-input-select__single-value': {
            padding: '22px 0 0 0 !important',
            color: Colors.Text,
            font: 'inherit',
            fontWeight: 'normal',
            fontSize: 16,
        },

        '& .multiple-input-select__option--is-focused': {
            backgroundColor: Colors.LightGraySecondary4,
        },

        '& .multiple-input-select__input': {
            fontSize: 16,
            '& input': {
                height: 'auto',
                bottom: 5,
            },
        },

        '& .multiple-input-select--is-disabled': {
            '& .multiple-input-select__control:before': {
                borderBottom: `1px dashed ${Colors.LightGraySecondary}`,
            },

            '& .multiple-input-select__control:after': {
                border: 'none !important',
            },

            '& .MuiSvgIcon-root': {
                fill: Colors.Gray,
                opacity: 0.5,
            },
        },

        '& .multiple-input-select__control--is-disabled': {
            backgroundColor: Colors.White,
            border: 'none !important',
        },
    },
    helperText: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        color: Colors.Error,
        fontSize: 12,
        marginTop: 3,
        lineHeight: '16px',
        marginLeft: 15,
        letterSpacing: '0.03333em',
        position:"absolute"
    },
    label: {
        color: Colors.LightGraySecondary2,
        transition:
            'color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
        left: 0,
        position: 'absolute',
        transform: 'translate(0, 24px) scale(1)',
        top: '-10px!important',
        zIndex: 4,
        marginLeft: 16,
        display: 'block',
        transformOrigin: 'top left',
        height: 24,
    },
    top: {
        transform: 'translate(0, 15px) scale(0.75) !important',
    },
    focus: {
        '& label': {
            color: Colors.Primary,
            transform: 'translate(0, 15px) scale(0.75) !important',
            top: '-13px',
            marginLeft: 16,
        },
        '& .multiple-input-select__control:after': {
            borderBottom: '2px solid ' + Colors.Primary + '!important',
            zIndex: 3,
            position: 'absolute',
            bottom: 0,
        },
    },

    errors: {
        '& label': {
            color: Colors.PlaceHolder,
        },
        '& .multiple-input-select__control:after': {
            borderBottom: '2px solid ' + Colors.Error + '!important',
        },
        '& .multiple-input-select__control:before': {
            borderBottom: '2px solid ' + Colors.Error + '!important',
        },
    },

    disabledRoot: {
        pointerEvents: 'none',
    },
    disabledLabel: {
        opacity: 0.8,
    },
    asterisk: {
        color: Colors.Error,
    },
    closeButton: {
        zIndex: 5,
        visibility: 'hidden',
    },
    rotated: {
        transform: 'rotate(180deg)',
    },
    chevron: {
        zIndex: 5,
        marginRight: -3,
    },
    readOnlyRoot: {
        pointerEvents: 'none',
        '& *': {
            background: 'transparent !important'
        },
        '& .multiple-input-select--is-disabled .multiple-input-select__control:before': {
            borderBottom: '1px solid ' + Colors.Border
        },
        '& .multiple-input-select__indicators': {
            visibility: 'hidden'
        }
    },
    option: {
        '&:hover': {
            backgroundColor: Colors.LightGraySecondary4,
            marginLeft: -10,
            marginRight: -10,
            paddingLeft: 10,
            paddingRight: 10
        }
    },
    paginateContainer: {
        height: 55,
        paddingBottom: 0,
        marginBottom: 0,
    }
}));
export const customStyle = {
    option: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: state.isSelected
            ? Colors.LightGraySecondary4
            : 'transparent',
        color: Colors.Text,
        '&:hover': {
            backgroundColor: Colors.LightGraySecondary4,
        }
    }),
    control: (base: any) => ({
        ...base,
        flex: 1,
        border: '0 !important',
        boxShadow: '0 !important',

        '&:hover': {
            borderBottom: '3px solid ' + 'black',
        },

        '&:focus': {
            '&:before': {
                borderBottom: '2px solid ' + 'black',
            },
        },
    }),
    menuPortal: (base: any) => ({...base, zIndex: 9999}),
};
export type AsyncSelectFieldProps = {
    id?: string;
    className?: string;
    title?: string;
    dataQa?: string;
    helperText?: string;
    icon?: React.ReactNode;
    value: any;
    loadOptions: any;
    onInputChange?: ((newValue: string, actionMeta: InputActionMeta) => void) &
        ((newValue: string, actionMeta: InputActionMeta) => void);
    onChange: (value: any) => void;
    cacheUniqs?: unknown[];
    menuIsOpen?: boolean;
    onMenuOpen?: () => void;
    onMenuClose?: () => void;
    maxMenuHeight?: number;
    disabled?: boolean;
    defaultValue?: { label: string; value: any };
    required?: boolean;
    setFieldError?: (name: string, value: string | undefined) => void;
    disableClearable?: boolean;
    defaultOptions?: boolean;
    formatOptionLabel?: (option: any, labelMeta: FormatOptionLabelMeta<any, boolean>) => React.ReactNode
    isOptionDisabled?: (option: any, labelMeta: any) => boolean | false;
    filterOption?: FilterOption;
    isOptionSelected?: (option: any, options: any) => boolean;
    forceEnabled?: boolean,
    inDialog?: boolean,
    useAny?:boolean,
    isMulti?:boolean,
    closeMenuOnSelect?:boolean,
    hideSelectedOptions?:boolean,
    placeholder?:string
};